<template>
  <space-detail
    breadcrumb-route="challenges"
    item-type="challenges"
    item-type-single="challenge"
  />
</template>

<script>
//import CollectiveDetail from '@/views/apps/collectives/components/CollectiveDetail.vue';

export default {
  name: 'ChallengeDetails',
  components: {
    SpaceDetail: () => import('@/views/apps/collectives/components/CollectiveDetail.vue' /* webpackChunkName: "SpaceDetail" */),
  },
};
</script>
